export function FormInputComponentDecorator(props) {

    const markup = { __html: props.fieldObject.helpText };

    return (
        <>
            { props.children }
            { props.error[props.fieldObject.uniqueName] && <span className='invalid-feedback'>{props.error[props.fieldObject.uniqueName]}</span> }               
            { typeof props.fieldObject.helpText === 'function' 
                ? <div id={props.fieldObject.uniqueName + 'Help'} className="form-text"><props.fieldObject.helpText/></div> 
                : props.fieldObject.helpText && <div id={props.fieldObject.uniqueName + 'Help'} className="form-text" dangerouslySetInnerHTML={markup}/> }
        </>        
    );
}